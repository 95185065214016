import React from 'react'
import { Icon, EidLogoIcon } from 'packages/eid-icons'
import { Header, Tooltip } from 'packages/eid-ui'
import { styled, Typography, Link, Box } from '@material-ui/core'
import { useSwrApiGet } from 'packages/core'
import LanguageMenu from './LanguageMenu'
import AccountDropdown from './AccountDropdown'
import { useTranslation } from 'react-i18next'

const StyledChip = styled('div')({
    position: 'absolute',
    top: '0px',
    left: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ff6100 !important',
    color: '#ffffff',
    height: '13px',
    maxWidth: '45px',
    minWidth: '24px',
    transform: 'skew(-20deg)',
    borderRadius: '2px',
    '@media (max-width:374px)': {
        display: 'none',
    },
})

const itemStyles = {
    minWidth: '30px',
    padding: '0px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const MobileHeader = ({ handleSearch, config }) => {
    const { t } = useTranslation()

    const { data: environmentData } = useSwrApiGet(
        () =>
            config.showEnvironment ? '/api/Application/EnvironmentName' : null,
        {
            dedupingInterval: 3600000,
        },
    )

    return (
        <Header.Mobile bgColor={config.bgColor}>
            <div
                style={{
                    width: '100%',

                    display: 'flex',
                }}
            >
                <Link
                    href="/"
                    underline="none"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Header.Item style={{ ...itemStyles, padding: 0 }}>
                        {config.appLogo.type === 'base64' ? (
                            <img
                                style={config.appLogo.style}
                                src={config.appLogo.value}
                                alt={config.appLogo.name}
                            />
                        ) : config.showEidLogo ? (
                            <EidLogoIcon />
                        ) : (
                            <Icon name={config.appLogo.value} />
                        )}
                    </Header.Item>
                </Link>
                {environmentData?.data && (
                    <Box position="relative" width="32px">
                        <Tooltip title={environmentData.data}>
                            <StyledChip>
                                <Typography
                                    noWrap
                                    style={{
                                        fontSize: '8px',
                                        padding: '0px 1.5px',
                                    }}
                                >
                                    {environmentData.data}
                                </Typography>
                            </StyledChip>
                        </Tooltip>
                    </Box>
                )}

                <Header.RightSection>
                    <Header.Item style={itemStyles}>
                        <LanguageMenu
                            showCone={false}
                            anchorPosition={{
                                right: '-32px',
                                top: '54px',
                            }}
                        />
                    </Header.Item>

                    <Header.Item style={itemStyles}>
                        <AccountDropdown />
                    </Header.Item>
                </Header.RightSection>
            </div>

            {config.search?.show && handleSearch && (
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Header.Search
                        styleData={config.search?.style}
                        placeholder={t('MyIdentity_Search')}
                        handleSearch={handleSearch}
                    />
                </div>
            )}
        </Header.Mobile>
    )
}
export default MobileHeader
