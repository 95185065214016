import React, { useState } from 'react'
import { Grid, Box, Typography, Collapse } from '@material-ui/core'
import {
    MyIdentityLayout,
    SubHeader,
    TableContainer,
    FiltersColumn,
} from 'components'
import TableView from './TableView'
import DelegationCard from './DelegationCard'
import AssignmentCard from './AssignmentCard'
import {
    useIsSmallScreen,
    isNilOrEmpty,
    useGetControlsAccess,
} from 'packages/core'
import { useApplicationSettings, useListingData } from 'hooks'
import { useAppState } from 'appContext'
import { Header, Loader } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import { Card as FilterCard, ToggleSwitch } from 'packages/eid-ui'
import AdvancedFilter from 'components/EidFilter/AdvancedFilter'
import * as DynamicComponents from 'components/DynamicComponents'
import { config, SwitchContainer } from './utils'
import CustomButton from 'components/CustomButton'

const subHeaderHeight = 105

const renderPeopleGrid = (rows, route, assignedToMe, fallbackMessage) => {
    if (rows.length === 0) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                height="40vh"
                margin="auto"
                alignItems="center"
            >
                <Typography variant="body2">{fallbackMessage}</Typography>
            </Box>
        )
    }

    let CardComponent

    if (assignedToMe) {
        CardComponent = AssignmentCard
    } else {
        CardComponent = DelegationCard
    }

    return (
        <Grid container spacing={2}>
            {rows.map((r, index) => (
                <Grid item xs={12} sm={6} md={4} key={'people-grid-' + index}>
                    <CardComponent data={r} route={route} />
                </Grid>
            ))}
        </Grid>
    )
}

const PermanentDelegations = ({ route }) => {
    const { t } = useTranslation()

    const [{ sortBy, sortOrder, assignedToMe }, dispatch] = useAppState()

    const configToUse = assignedToMe
        ? config.permanentAssignments
        : config.permanentDelegations

    const { dataSource, attributes, subHeaderButton, filters } = configToUse

    const fallbackMessage = t('MyIdentity_NoDelegationsFound')

    const isSmallScreen = useIsSmallScreen()

    const [showCardView, toggleShowCardView] = useState(false)
    const [openFilters, setOpenFilters] = useState(false)

    const searchIdentity = (text) => {
        dispatch({
            type: 'SEARCH_IDENTITIES',
            payload: text,
        })
    }

    const controls = useGetControlsAccess().map((c) => c.name)

    const hasAccessToAdvancedSearchFilters =
        filters && controls.includes(filters[0].requireAccess?.control)

    const filteredAttributes = attributes.filter(
        (a) =>
            isNilOrEmpty(a.requireAccess?.control) ||
            controls.includes(a.requireAccess?.control),
    )
    const { list, loading, loadingMore, infiniteScrollObervableDiv } =
        useListingData(dataSource.url)

    const renderComponent = (name) => {
        const DynamicComponentToRender = DynamicComponents[name]

        return <DynamicComponentToRender />
    }
    const { data: appConfig } = useApplicationSettings()

    const styleConfig = appConfig.style
    const subHeaderButtonComponent =
        subHeaderButton &&
        (controls.includes(subHeaderButton.requireAccess.control) ? (
            renderComponent(subHeaderButton.name)
        ) : (
            <></>
        ))

    const desktopFilters = (
        <Box>
            <Collapse in={openFilters} timeout={300} unmountOnExit>
                <FiltersColumn openFilters={openFilters}>
                    <FilterCard
                        fontColor="black"
                        cardTitle={t('MyIdentity_AdvancedSearch')}
                        icon={<Icon name="AdvancedSearch" color="#959598" />}
                    >
                        <AdvancedFilter
                            forms={filters && filters[0].forms}
                            tags={null}
                        />
                    </FilterCard>
                </FiltersColumn>
            </Collapse>
        </Box>
    )

    const listing = (
        <Box position="relative">
            {styleConfig.search?.show && (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            padding: '8px 0px 16px 0px',
                            width: isSmallScreen ? '100%' : '33%',
                            float: 'right',
                        }}
                    >
                        <Header.Search
                            styleData={styleConfig.search?.style}
                            placeholder={t('MyIdentity_Search')}
                            handleSearch={searchIdentity}
                            showClearIcon={false}
                        />
                    </div>
                    {/* NOTE: Below empty div is intentional just to clear the float which is causing the overlap */}
                    <div style={{ clear: 'both' }}></div>
                </div>
            )}
            {loading && <Loader margin="auto" />}

            {list && (
                <>
                    {isSmallScreen && (
                        <>
                            {openFilters && (
                                <Collapse
                                    in={openFilters}
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Box boxShadow="0 4px 6px -2px rgba(0, 0, 0, 0.1)">
                                        <FiltersColumn
                                            width="100%"
                                            openFilters={openFilters}
                                        >
                                            <FilterCard
                                                fontColor="black"
                                                cardTitle={t(
                                                    'MyIdentity_AdvancedSearch',
                                                )}
                                                icon={
                                                    <Icon
                                                        name="AdvancedSearch"
                                                        color="#959598"
                                                    />
                                                }
                                            >
                                                <AdvancedFilter
                                                    forms={
                                                        filters &&
                                                        filters[0].forms
                                                    }
                                                    tags={null}
                                                />
                                                <Box
                                                    display="flex"
                                                    marginTop="24px"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <CustomButton
                                                        label={t(
                                                            'MyIdentity_Apply',
                                                        )}
                                                        onClick={() =>
                                                            setOpenFilters(
                                                                false,
                                                            )
                                                        }
                                                    />
                                                </Box>
                                            </FilterCard>
                                        </FiltersColumn>
                                    </Box>
                                </Collapse>
                            )}
                            {!openFilters && (
                                <Collapse
                                    in={!openFilters}
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    {renderPeopleGrid(
                                        list,
                                        route,
                                        assignedToMe,
                                        fallbackMessage,
                                    )}
                                </Collapse>
                            )}
                        </>
                    )}

                    {!isSmallScreen && (
                        <>
                            {showCardView ? (
                                <TableContainer
                                    openFilters={openFilters}
                                    width="100% !important"
                                >
                                    {renderPeopleGrid(
                                        list,
                                        route,
                                        assignedToMe,
                                        fallbackMessage,
                                    )}
                                </TableContainer>
                            ) : (
                                <>
                                    <TableContainer openFilters={openFilters}>
                                        <TableView
                                            fallbackMessage={fallbackMessage}
                                            headings={filteredAttributes}
                                            route={route}
                                            data={list}
                                            sort={(sortBy, sortOrder) => {
                                                dispatch({
                                                    type: 'SORT_IDENTITIES',
                                                    payload: {
                                                        sortBy,
                                                        sortOrder,
                                                    },
                                                })
                                            }}
                                            sortBy={sortBy}
                                            sortOrder={sortOrder}
                                        />
                                    </TableContainer>
                                </>
                            )}

                            {openFilters && desktopFilters}
                        </>
                    )}
                </>
            )}
        </Box>
    )

    const subHeader = (
        <>
            <Box width="100%">
                <SubHeader
                    showNavTabs={true}
                    showCardView={showCardView}
                    toggleCardView={(showCardView) =>
                        toggleShowCardView(showCardView)
                    }
                    showFilters={openFilters}
                    toggleFilters={() => setOpenFilters((prev) => !prev)}
                    showFiltersButton={hasAccessToAdvancedSearchFilters}
                />
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <SwitchContainer assignedToMe={assignedToMe}>
                        <Typography className="Label">
                            {t('MyIdentity_CreatedByMe')}
                        </Typography>
                        <ToggleSwitch
                            value={assignedToMe}
                            onChange={() =>
                                dispatch({
                                    type: 'SET_PROP',
                                    payload: {
                                        key: 'assignedToMe',
                                        value: !assignedToMe,
                                    },
                                })
                            }
                        />

                        <Typography className="SubLabel">
                            {t('MyIdentity_AssignedToMe')}
                        </Typography>
                    </SwitchContainer>
                    {assignedToMe ? <></> : subHeaderButtonComponent}
                </Box>
            </Box>
        </>
    )

    return (
        <MyIdentityLayout subHeader={subHeader}>
            <Box paddingTop={`${subHeaderHeight + 15}px`} paddingX="10px">
                {listing}
            </Box>
            {infiniteScrollObervableDiv}
            {loadingMore && <Loader margin="auto" />}
        </MyIdentityLayout>
    )
}

export default PermanentDelegations
