import React from 'react'
import { GlobalMenu } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { useAllApplications } from 'hooks'
import { useConfiguration } from 'appConfiguration/configurations'
import { SubPathLevel } from '../../../appConfiguration'
import appConfig from 'config'

const appNameMaps = {
    EIDEmpowerIDWeb: 'EmpowerID',
    EIDAzureAnalyticsMS: 'AzureAnalytics',
    EIDITShopMS: 'ITShop',
    EIDMyIdentity: 'MyIdentity',
    EIDMyTasks: 'MyTasks',
    EIDResourceAdminMS: 'ResourceAdmin',
    EIDRiskAnalyticsMS: 'RiskAnalytics',
}

const MobileMenu = ({ history, ...rest }) => {
    const {
        location: { pathname },
    } = history

    const { t } = useTranslation()
    const { dropDownOptions } = useConfiguration()

    const isActive = (route, isTab) => {
        const splittedPath = pathname.split('/')
        let area = ''
        if (isTab) {
            area = `${splittedPath[1 + SubPathLevel]}/${
                splittedPath[2 + SubPathLevel]
            }`
        } else {
            area = splittedPath[1 + 1]
        }
        return (
            `${appConfig.APP_SUBPATH}/${area}`.toLowerCase() ===
            route.toLowerCase()
        )
    }

    const { data, isLoading } = useAllApplications()

    const eidApp = data?.find((a) => a.applicationName === 'EIDEmpowerIDWeb')

    const routes = dropDownOptions.map((a) => ({
        name: t(a.title),
        route: a.route,
        active: isActive(a.route, false),
        submenu: a.tabbedOptions
            ? a.tabbedOptions.map((o) => ({
                  name: t(o.title),
                  route: a.route + o.route,
                  active: isActive(a.route + o.route, true),
              }))
            : [],
    }))
    return (
        <GlobalMenu.Mobile
            loading={isLoading}
            routes={routes}
            settings={{ signOut: { label: t('MyIdentity_SignOut') } }}
            eid={
                eidApp
                    ? {
                          name: appNameMaps[eidApp.applicationName],
                          displayName: eidApp.applicationDisplayName,
                          url: eidApp.applicationAbsoluteUrl,
                      }
                    : undefined
            }
            apps={data
                ?.filter((f) => f.applicationName !== 'EIDEmpowerIDWeb')
                .map((a) => ({
                    name: appNameMaps[a.applicationName],
                    displayName: a.applicationDisplayName,
                    url: a.applicationAbsoluteUrl,
                }))}
            current="MyIdentity"
            colorCodes={{ primary: '#307fc1', secondary: '#000000' }}
            {...rest}
        />
    )
}

export default withRouter(MobileMenu)
