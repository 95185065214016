import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'

export const APPLICATION_SETTINGS_KEY = 'APPLICATION_SETTINGS'
export const ALL_APPLICATIONS_KEY = 'ALL_APPLICATIONS'

export const useApplicationSettings = (settingsOverride) => {
    const callApi = useAxios()
    return useQuery(
        APPLICATION_SETTINGS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: settingsOverride
                    ? `/api/application/settings?settingsOverride=${settingsOverride}`
                    : '/api/application/settings',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

export const useAllApplications = () => {
    const callApi = useAxios()
    return useQuery(
        ALL_APPLICATIONS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/access/applications',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}
