import React from 'react'
import { useIsSmallScreen } from 'packages/core'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

const GlobalMenu = ({ hideSideNavigation }) => {
    const isSmallScreen = useIsSmallScreen()
    return isSmallScreen ? (
        <MobileMenu hideMSAppsMenu={hideSideNavigation} />
    ) : (
        !hideSideNavigation && (
            <div style={{ marginTop: '24px' }}>
                <DesktopMenu />
            </div>
        )
    )
}

export default GlobalMenu
